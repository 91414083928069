<template>
	<div class=''>
		<div class="header_box flex col-center row-center">
			<div class="line"></div>
			<div class="mr20 ml20">{{newsD.releaseTime}}</div>
			<img src="@/assets/images/news/weibo.png" class="w29 h24 mr20" @click="toweibo()">
			<!-- <img src="@/assets/images/news/wx.png" class="w29 h24 mr20"> -->
			<img src="@/assets/images/news/zone.png" class="w29 h24 mr20" @click="toQQzone(url,title)">
			<img src="@/assets/images/news/dian.png" class="w23 h5 mr20">
			<div class="line"></div>
		</div>
		<div class="container">
			<div>
				<div class="container_header flex row-center pt60 pb60 fs18 color9D">
					<span>来源:{{newsD.source|sourceFn}}</span>&nbsp;&nbsp;&nbsp
					<span>发布时间:{{newsD.releaseTime}}</span>&nbsp;&nbsp;&nbsp
					<span>浏览量:{{newsD.views}}</span>
				</div>
				<div class="color0 fs46 fw-600 text-center mb40">{{newsD.contentName}}</div>
				<div style="margin: 0 auto; width: 800px;">
					<div class="mb120 ql-editor" v-html="newsD.content">
					</div>
				</div>
				<div class="pt33 pb36 pl34 pr38 flex w900" style="background: #F9F9F9; margin: 0 auto;"
					@click="btnNews(nextNewsD.id)" v-if="nextNewsD.contentName">
					<img :src="nextNewsD.pcPic" class="w286 h228">
					<div class="ml27 flex row-column row-between pb17">
						<div class="fs24">{{nextNewsD.contentName}}</div>
						<div class="fs18 color9D">{{nextNewsD.newsIntro}}</div>
						<div class="fs18 color9D">{{nextNewsD.releaseTime}}</div>
					</div>
				</div>
			</div>
			<div class="flex row-center mb120 mt60">
				<div class="btn_box mr40 pointer" @click="update(newsD.upId)">上一篇</div>
				<div class="btn_box mr40 pointer" @click="update(newsD.downId)">下一篇</div>
				<div class=" btn_box btn_box_0 pointer" @click="goBack">返回列表</div>
			</div>
		</div>
	</div>
</template>

<script>
	import { getNewsDetail } from '@/api'
	import { toQQzone, toQQ, toWeibo, toWechat } from '@/utils/share.js'
	export default {
		name: 'newsD',
		data() {
			return {
				id: null,
				newsD: {},
				nextId: null,
				nextNewsD: {},
				title: "", //需要分享的标题
				_url: '', //自定义网站链接地址 https://www.baidu.com/
				url: '', //获取当前网页地址window.location.href
				content: '', //
				sharesinastring: null //跳转的url地址;
				//分享微博。qq  qq空间等
			};
		},
		filters: {
			sourceFn(val) {
				return val == 0 ? '铭智生科' : '转载'
			}
		},
		components: {},

		created() {
			this.$store.commit('editRouterName', this.$route.name)
			this.id = this.$route.params.id
			this.url = window.location.href
			this.getNewsDetail()
		},

		mounted() {},

		methods: {
			async getNewsDetail() {
				const res = await getNewsDetail({ id: this.id })
				this.newsD = res.data
				this.title = res.data.contentName
				this.content = res.data.content
				this.newsIntro = res.data.newsIntro
				this.nextId = res.data.downId
				if (this.nextId != 0) {
					this.getNextD(this.nextId)
				} else {
					this.nextNewsD = {}
				}
			},
			//获取下一篇新闻详情
			async getNextD(id) {
				document.documentElement.scrollTop = 0;
				const res = await getNewsDetail({ id })
				this.nextNewsD = res.data

			},

			btnNews(id) {
				this.id = id
				this.getNewsDetail()
			},
			update(id) {
				if (id == 0) {
					return this.$message.warning('没有文章了')
				} else {
					this.id = id
					this.getNewsDetail()
				}
			},
			//分享到微博
			toweibo() {
				var sharesinastring = 'http://service.weibo.com/share/share.php?title=' +
					encodeURIComponent(this.newsIntro + '「' + this.title + '」' + ' 点这里' + this.url);
				window.open(sharesinastring, '_blank'); //微博在新页面打开
			},
			//分享到qq空间
			// toqqZone () {
			//   let url = this.url
			//   let title = this.title
			//   window.open(`https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=${url}&title=${title}&desc=${title}&summary=${title}&site=${url}`)
			// },
			//分享到qq空间
			toQQzone(url, title) {
				// toQQzone(url, title);
				let urlData =
					`https://sns.qzone.qq.com/cgi-bin/qzshare/cgi_qzshare_onekey?url=${this.url}&sharesource=qzone&title=${this.title}&summary=${this.newsIntro}`
				console.log(urlData);
				window.open(urlData, '_blank')
			},
			goBack() {
				this.$router.back()
			}
		}
	}
</script>
<style lang='scss' scoped>
	.header_box {
		height: 110px;
		background: #f9f9f9;

		.line {
			width: 200px;
			height: 1px;
			border: 0.5px solid #dbdbdb;
		}
	}

	.container {
		img {
			display: inline-block;
		}
	}

	.btn_box {
		width: 178px;
		height: 54px;
		background: #ffffff;
		border: 2px solid #272727;
		font-size: 16px;
		font-family: PingFangSC-Medium, PingFang SC;
		font-weight: 500;
		color: #272727;
		text-align: center;
		line-height: 54px;
	}

	.btn_box_0 {
		background: #272727;
		color: #ffffff;
	}
</style>
